'use client';

import Link from 'next/link';

const tabList = [
  {
    label: '期刊',
    name: 'music',
    href: '/',
  },
  {
    label: '专栏',
    name: 'essay',
    href: '/essay/list',
  },
];

export default function Tab({ type }: { type: string }) {
  return (
    <div className="flex items-center gap-x-24px flex-wrap w-full h-33px">
      {tabList.map((item) => (
        <Link
          href={item.href}
          key={item.name}
          className={`flex flex-col justify-between items-center w-fit h-full text-17px leading-24px text-center cursor-pointer ${
            item.name === type ? 'text-brand' : 'text-base hover:text-brand'
          }`}
        >
          <div>{item.label}</div>
          {item.name === type && <div className="w-30px h-2px mt-7px bg-brand" />}
        </Link>
      ))}
    </div>
  );
}
